import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import MoneyTextField from '~/components/CustomUi/MoneyTextField';
import { AddCircle, RemoveCircle } from '@material-ui/icons';

const useStyles = makeStyles({
  gridForm: {
    marginTop: '7px',
  },
  btRow: {
    marginTop: '5px',
  },
});

function PlanoPagamentoForm({
  formik,
  section,
  sectionTitle,
  indice,
  handleAddRow,
  handleRemoveRow,
}) {
  const classes = useStyles();

  const getPorcentagem = (valor, total) => {
    return parseFloat((valor * 100) / total).toFixed(2);
  };

  const getValor = (porcentagem, total) => {
    return parseFloat((porcentagem * total) / 100).toFixed(2);
  };

  const handleChangeDescontoEmReais = () => {
    const valorTotal = formik.values[section][indice].valor;
    const { desconto } = formik.values[section][indice];

    if (formik.values[section][indice].emreais) {
      formik.setFieldValue(
        `${section}[${indice}].desconto`,
        getPorcentagem(parseFloat(desconto), valorTotal)
      );
    } else {
      formik.setFieldValue(
        `${section}[${indice}].desconto`,
        getValor(Number(desconto), valorTotal)
      );
    }

    formik.setFieldValue(
      `${section}[${indice}].emreais`,
      !formik.values[section][indice].emreais
    );
  };

  const handleChangeDiaUtil = () => {
    formik.setFieldValue(
      `${section}[${indice}].diautil`,
      !formik.values[section][indice].diautil
    );
  };

  return (
    <>
      {indice === 0 && (
        <Grid item xs={12}>
          <Typography
            variant="body1"
            color="primary"
            className={classes.titleSection}
          >
            {sectionTitle}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12} md={section !== 'parcela' ? 3 : 2}>
        <MoneyTextField
          name={`${section}[${indice}].valor`}
          label={`Valor da ${sectionTitle}`}
          placeholder="Digite o valor"
          variant="filled"
          fullWidth
          value={formik.values[section][indice].valor}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched[section] &&
            formik.touched[section].valor &&
            formik.errors[section] &&
            formik.errors[section].valor
          }
          helperText={
            formik.touched[section] &&
            formik.touched[section].valor &&
            formik.errors[section] &&
            formik.errors[section].valor
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={section !== 'parcela' ? 3 : 2}>
        <TextField
          type="date"
          variant="filled"
          format="dd/MM/yyyy"
          label="Vencimento"
          name={`${section}[${indice}].data`}
          fullWidth
          value={formik.values[section][indice].data}
          defaultValue={formik.values[section][indice].data}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched[section] &&
            formik.touched[section].data &&
            formik.errors[section] &&
            formik.errors[section].data
          }
          helperText={
            formik.touched[section] &&
            formik.touched[section].data &&
            formik.errors[section] &&
            formik.errors[section].data
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          name={`${section}[${indice}].quantidade`}
          label="Quantidade"
          placeholder="Digite o valor"
          variant="filled"
          type="number"
          fullWidth
          value={formik.values[section][indice].quantidade}
          onChange={formik.handleChange}
          onBlur={event => {
            formik.handleBlur(event);
          }}
          error={
            formik.touched[section] &&
            formik.touched[section].quantidade &&
            formik.errors[section] &&
            formik.errors[section].quantidade
          }
          helperText={
            formik.touched[section] &&
            formik.touched[section].quantidade &&
            formik.errors[section] &&
            formik.errors[section].quantidade
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={section !== 'parcela' ? 3 : 2}>
        <MoneyTextField
          name={`${section}[${indice}].desconto`}
          label="Desconto"
          placeholder={
            formik.values[section][indice].emreais
              ? 'Digite o valor'
              : 'Digite uma porcentagem'
          }
          variant="filled"
          fullWidth
          value={formik.values[section][indice].desconto}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched[section] &&
            formik.touched[section].desconto &&
            formik.errors[section] &&
            formik.errors[section].desconto
          }
          helperText={
            formik.touched[section] &&
            formik.touched[section].desconto &&
            formik.errors[section] &&
            formik.errors[section].desconto
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {formik.values[section][indice].emreais ? 'R$' : '%'}
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              name={`${section}[${indice}].emreais`}
              onChange={handleChangeDescontoEmReais}
              checked={!formik.values[section][indice].emreais}
              color="primary"
            />
          }
          label="Porcentagem?"
        />
      </Grid>
      {section === 'parcela' && (
        <>
          <Grid item xs={12} md={2}>
            <TextField
              name={`${section}[${indice}].demaisvencimentos`}
              label="Demais vencimentos"
              placeholder="Digite o valor"
              variant="filled"
              type="number"
              fullWidth
              value={formik.values[section][indice].demaisvencimentos}
              onChange={formik.handleChange}
              onBlur={event => {
                formik.handleBlur(event);
              }}
              error={
                formik.touched[section] &&
                formik.touched[section].demaisvencimentos &&
                formik.errors[section] &&
                formik.errors[section].demaisvencimentos
              }
              helperText={
                formik.touched[section] &&
                formik.touched[section].demaisvencimentos &&
                formik.errors[section] &&
                formik.errors[section].demaisvencimentos
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={1} className={classes.gridForm}>
            <FormControlLabel
              control={
                <Checkbox
                  name={`${section}[${indice}].diautil`}
                  onChange={handleChangeDiaUtil}
                  checked={!formik.values[section][indice].diautil}
                  color="primary"
                />
              }
              label="Dia útil?"
            />
          </Grid>
        </>
      )}
      <Grid item xs={6} md={1} className={classes.btRow}>
        <IconButton onClick={() => handleAddRow(section)}>
          <AddCircle color="primary" />
        </IconButton>
        {indice > 0 && (
          <IconButton onClick={() => handleRemoveRow(section, indice)}>
            <RemoveCircle color="error" />
          </IconButton>
        )}
      </Grid>
    </>
  );
}

PlanoPagamentoForm.propTypes = {
  section: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  formik: PropTypes.shape().isRequired,
  indice: PropTypes.number.isRequired,
  handleAddRow: PropTypes.func.isRequired,
  handleRemoveRow: PropTypes.func.isRequired,
};

export default PlanoPagamentoForm;
