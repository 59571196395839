import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from 'devextreme-react';
import { Column, Paging, Selection } from 'devextreme-react/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { updateMateriasSelecionadas } from '~/store/modules/new-contract/actions';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    maxHeight: '350px',
    overflow: 'auto',
  },
});

const modalidades = {
  1: 'EAD',
  2: 'Presencial',
};

function TableMaterias({ showMateriasGratuitas }) { // eslint-disable-line
  const classes = useStyles();
  const dispatch = useDispatch();
  const materias = useSelector(state => state.newContract.materias);
  const dadosContrato = useSelector(state => state.newContract.dadosContrato);

  const onSelectionChanged = React.useCallback(
    data => {
      dispatch(updateMateriasSelecionadas(data.selectedRowsData));
    },
    [] // eslint-disable-line
  );

  const handlePrepareCell = event => {
    if (event.rowType === 'data') {
      if (event.column.dataField === 'modalidade') {
        const modalidadesMateria = event.data.modalidade.map(
          modalidade => modalidades[modalidade]
        );
        event.cellElement.innerText = modalidadesMateria.join(', ');
      }
    }
  };

  return (
    <div className={classes.root}>
      <DataGrid
        id="gridContainer"
        dataSource={materias}
        showBorders
        selectedRowKeys={dadosContrato.materiasSelecionadas}
        onSelectionChanged={data => onSelectionChanged(data)}
        noDataText="Nenhuma matéria encontrada"
        onCellPrepared={handlePrepareCell}
      >
        <Paging enabled={false} />
        <Selection mode="multiple" showCheckBoxesMode="always" />
        <Column dataField="materia" caption="MATÉRIA" />
        <Column dataField="modalidade" caption="MODALIDADE" />
      </DataGrid>
    </div>
  );
}

TableMaterias.propTypes = {
  showMateriasGratuitas: PropTypes.bool,
};

TableMaterias.defaultProps = {
  showMateriasGratuitas: false,
};

export default TableMaterias;
