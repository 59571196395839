import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { format } from 'date-fns';
import {
  storeContract,
  updateStep,
} from '~/store/modules/new-contract/actions';
import { showLoading } from '~/store/modules/loading/actions';
import ContratoSucessoModal from './ContratoSucessoModal';
import ModalAssinatura from './ModalAssinatura';
import termo_uso_mock from './termo_uso_mock';

const useStyles = makeStyles({
  mainContainer: {
    padding: '20px',
  },
  gridTitular: {
    background: '#E4E6EE',
    borderRadius: '20px',
    height: '100%',
  },
  gridTitularContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  titleSection: {
    margin: '20px 0 0',
  },
  button: {
    minWidth: '171px',
    borderRadius: '200px',
    marginLeft: '15px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  box: {
    borderRadius: '4px',
    border: '1px solid #F2F2F2',
    padding: '20px',
  },
  boxTermos: {
    borderRadius: '12px',
    border: '1px solid #F2F2F2',
    padding: '30px',
    background: 'rgba(234, 236, 245, 0.4)',
    maxHeight: '500px',
    overflowY: 'auto',
  },
});

function Conferencia({ handleCancel }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const cursos = useSelector(state => state.cursos.data);
  const turmas = useSelector(state => state.turmas.data);
  const escolaridades = useSelector(state => state.lookup.escolaridade);
  const currentStep = useSelector(state => state.newContract.currentStep);
  const dadosAluno = useSelector(state => state.newContract.dadosAluno);
  const dadosTitular = useSelector(state => state.newContract.dadosTitular);
  const dadosContrato = useSelector(state => state.newContract.dadosContrato);
  const dadosFinanceiro = useSelector(
    state => state.newContract.dadosFinanceiro
  );
  const dadosTurma = useSelector(state => state.newContract.dadosTurma);
  const visita = useSelector(state => state.newContract.visita);
  const newContract = useSelector(state => state.newContract);
  // const dadosFinanceiro = useSelector(
  //   state => state.newContract.dadosFinanceiro
  // );
  const [openModalSuccess /* setOpenModalSuccess */] = React.useState(false);

  const getPorcentagemValor = (valor, total) => {
    return parseFloat((valor * 100) / total).toFixed(2);
  };

  const getValorReal = (porcentagem, total) => {
    return parseFloat((porcentagem * total) / 100).toFixed(2);
  };

  const gerarContrato = () => {
    dispatch(showLoading('Gerando contrato...'));

    // armazena apenas os ids das turmas
    const turmasIds = dadosTurma.map(turma => turma.turma_id);
    const materias = newContract.materias.map(materia => materia.materia_id);

    const payload = {
      dadosAluno,
      dadosTitular: dadosTitular.cgcCpf ? dadosTitular : dadosAluno,
      dadosContrato: {
        visitaId: visita.visita_id,
        vendedorId: dadosContrato.vendedorId,
        divulgadorId: dadosContrato.divulgadorId,
        fontId: visita.fonte,
        cursoId: dadosContrato.cursos[0],
        multiplosCursos: dadosContrato.cursos,
        obs: newContract.dadosObservacao.observacoes_gerais,
        obsPagamento: newContract.dadosObservacao.observacoes_cobranca,
        // Revisar campos abaixo
        percentualMatricula:
          (!dadosFinanceiro.matricula[0].emreais &&
            dadosFinanceiro.matricula[0].desconto) ||
          getPorcentagemValor(
            dadosFinanceiro.matricula[0].desconto,
            dadosFinanceiro.matricula[0].valor
          ),
        percentualParcela:
          (!dadosFinanceiro.parcela[0].emreais &&
            dadosFinanceiro.parcela[0].desconto) ||
          getPorcentagemValor(
            dadosFinanceiro.parcela[0].desconto,
            dadosFinanceiro.parcela[0].valor
          ),
        percentualMaterial:
          (!dadosFinanceiro.matricula[0].emreais &&
            dadosFinanceiro.material[0].desconto) ||
          getPorcentagemValor(
            dadosFinanceiro.material[0].desconto,
            dadosFinanceiro.material[0].valor
          ),
        descontoMatricula:
          (dadosFinanceiro.matricula[0].emreais &&
            dadosFinanceiro.matricula[0].valor -
              dadosFinanceiro.matricula[0].desconto) ||
          getValorReal(
            dadosFinanceiro.matricula[0].desconto,
            dadosFinanceiro.matricula[0].valor
          ),
        descontoParcela:
          (dadosFinanceiro.parcela[0].emreais &&
            dadosFinanceiro.parcela[0].valor -
              dadosFinanceiro.parcela[0].desconto) ||
          getValorReal(
            dadosFinanceiro.parcela[0].desconto,
            dadosFinanceiro.parcela[0].valor
          ),
        descontoMaterial:
          (dadosFinanceiro.material[0].emreais &&
            dadosFinanceiro.material[0].valor -
              dadosFinanceiro.material[0].desconto) ||
          getValorReal(
            dadosFinanceiro.material[0].desconto,
            dadosFinanceiro.material[0].valor
          ),
      },
      dadosFinanceiro,
      turmas: turmasIds,
      materias,
    };

    dispatch(storeContract(payload));
  };

  const showCursosSelected = () => {
    const cursosSelecteds = [];

    dadosContrato.cursos.map(cursoId => {
      const cursoFound = cursos.find(curso => curso.curso_id === cursoId);
      cursosSelecteds.push(cursoFound.descricao);
    });

    return cursosSelecteds.join(', ');
  };

  const showTurmasSelected = () => {
    return dadosTurma.map(turmaMapped => {
      const turmaFound = turmas.find(
        turma => turma.turma_id === turmaMapped.turma_id
      );

      return (
        <>
          {turmaFound.nome} - {turmaFound.descricao}
          <br />
        </>
      );
    });
  };

  const showProfessores = () => {
    return dadosTurma.map(turmaMapped => {
      const turmaFound = turmas.find(
        turma => turma.turma_id === turmaMapped.turma_id
      );

      return (
        <>
          {turmaFound.nome} - {turmaFound.professor}
          <br />
        </>
      );
    });
  };

  const getResumoPagamento = type => {
    if (!dadosFinanceiro[type] || !dadosFinanceiro[type][`${type}quantidade`]) {
      return 'Sem cobrança';
    }

    const quantidade = dadosFinanceiro[type][`${type}quantidade`];
    const valor = dadosFinanceiro[type][`${type}valor`];
    const data = dadosFinanceiro[type][`${type}data`];
    const demaisVencimento = dadosFinanceiro[type][`${type}demaisvencimentos`]
      ? `, demais vencimentos sempre no dia ${
          dadosFinanceiro[type][`${type}demaisvencimentos`]
        }`
      : '';

    return `${quantidade} x R$ ${valor} - 1º vencimento ${format(
      new Date(`${data}T00:00:00.000`),
      'dd/MM/yyyy'
    )}${demaisVencimento}`;
  };

  return (
    <>
      <Grid container spacing={2} className={classes.mainContainer}>
        <Grid item xs={12}>
          <Typography variant="h6" color="secondary">
            Verifique se está tudo certo antes de finalizar
          </Typography>
        </Grid>
        {dadosTitular.nome && (
          <>
            <Grid item xs={12} className={classes.titleSection}>
              <Typography variant="body1" color="primary">
                Dados do titular
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography variant="caption" color="primary">
                      NOME
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      {dadosTitular.nome}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography variant="caption" color="primary">
                      CPF
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      {dadosTitular.cgcCpf}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography variant="caption" color="primary">
                      DATA DE NASCIMENTO
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      {dadosTitular.nascimento &&
                        format(
                          new Date(`${dadosTitular.nascimento}T00:00:00.000`),
                          'dd/MM/yyyy'
                        )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography variant="caption" color="primary">
                      CELULAR
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      {dadosTitular.celular}
                    </Typography>
                  </Grid>
                  {dadosTitular.telefone_comercial && (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Typography variant="caption" color="primary">
                        TELEFONE
                      </Typography>
                      <Typography variant="body1" color="secondary">
                        {dadosTitular.telefone_comercial}
                      </Typography>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography variant="caption" color="primary">
                      CEP
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      {dadosTitular.cep}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography variant="caption" color="primary">
                      ENDEREÇO
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      {dadosTitular.logradouro}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography variant="caption" color="primary">
                      NÚMERO E COMPLEMENTO
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      {dadosTitular.endComplemento}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography variant="caption" color="primary">
                      BAIRRO
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      {dadosTitular.bairro}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography variant="caption" color="primary">
                      CIDADE
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      {dadosTitular.localidade}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography variant="caption" color="primary">
                      ESTADO
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      {dadosTitular.estado}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </>
        )}

        <Grid item xs={12} className={classes.titleSection}>
          <Typography variant="body1" color="primary">
            Dados do aluno
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.box}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="caption" color="primary">
                  NOME
                </Typography>
                <Typography variant="body1" color="secondary">
                  {dadosAluno.nome}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="caption" color="primary">
                  CPF
                </Typography>
                <Typography variant="body1" color="secondary">
                  {dadosAluno.cgcCpf}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="caption" color="primary">
                  DATA DE NASCIMENTO
                </Typography>
                <Typography variant="body1" color="secondary">
                  {format(
                    new Date(`${dadosAluno.nascimento}T00:00:00.000`),
                    'dd/MM/yyyy'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="caption" color="primary">
                  NOME DA MÃE
                </Typography>
                <Typography variant="body1" color="secondary">
                  {dadosAluno.nomeMae}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="caption" color="primary">
                  NOME DO PAI
                </Typography>
                <Typography variant="body1" color="secondary">
                  {dadosAluno.nomePai}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="caption" color="primary">
                  ESCOLARIDADE
                </Typography>
                <Typography variant="body1" color="secondary">
                  {dadosAluno.escolaridade &&
                    escolaridades.find(
                      escolaridade =>
                        escolaridade.intkey === dadosAluno.escolaridade
                    ).descricao}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="caption" color="primary">
                  CELULAR
                </Typography>
                <Typography variant="body1" color="secondary">
                  {dadosAluno.celular}
                </Typography>
              </Grid>
              {dadosAluno.telefone_comercial && (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="caption" color="primary">
                    TELEFONE
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    {dadosAluno.telefone_comercial}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="caption" color="primary">
                  CEP
                </Typography>
                <Typography variant="body1" color="secondary">
                  {dadosAluno.cep}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="caption" color="primary">
                  ENDEREÇO
                </Typography>
                <Typography variant="body1" color="secondary">
                  {dadosAluno.logradouro}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="caption" color="primary">
                  NÚMERO E COMPLEMENTO
                </Typography>
                <Typography variant="body1" color="secondary">
                  {dadosAluno.endComplemento}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="caption" color="primary">
                  BAIRRO
                </Typography>
                <Typography variant="body1" color="secondary">
                  {dadosAluno.bairro}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="caption" color="primary">
                  CIDADE
                </Typography>
                <Typography variant="body1" color="secondary">
                  {dadosAluno.localidade}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="caption" color="primary">
                  ESTADO
                </Typography>
                <Typography variant="body1" color="secondary">
                  {dadosAluno.estado}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.titleSection}>
          <Typography variant="body1" color="primary">
            Contrato
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.box}>
            <Grid container spacing={1}>
              {Boolean(dadosContrato.data_inicio_aulas) && (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="caption" color="primary">
                    INÍCIO DAS AULAS
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    {format(
                      new Date(
                        `${dadosContrato.data_inicio_aulas}T00:00:00.000`
                      ),
                      'dd/MM/yyyy'
                    )}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="caption" color="primary">
                  CURSO
                </Typography>
                <Typography variant="body1" color="secondary">
                  {showCursosSelected()}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.titleSection}>
          <Typography variant="body1" color="primary">
            Turma
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.box}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="caption" color="primary">
                  TURMA
                </Typography>
                <Typography variant="body1" color="secondary">
                  {showTurmasSelected()}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="caption" color="primary">
                  PROFESSOR (A)
                </Typography>
                <Typography variant="body1" color="secondary">
                  {showProfessores()}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.titleSection}>
          <Typography variant="body1" color="primary">
            Dados do parcelamento
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.box}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} lg={3}>
                <Typography variant="caption" color="primary">
                  MATRÍCULA
                </Typography>
                <Typography variant="body1" color="secondary">
                  {dadosFinanceiro.matricula && getResumoPagamento('matricula')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} lg={9}>
                <Typography variant="caption" color="primary">
                  PARCELAS
                </Typography>
                <Typography variant="body1" color="secondary">
                  {dadosFinanceiro.parcela && getResumoPagamento('parcela')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="primary">
                  MATERIAL DIDÁTICO
                </Typography>
                <Typography variant="body1" color="secondary">
                  {dadosFinanceiro.material && getResumoPagamento('material')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.boxTermos}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {termo_uso_mock.termo_uso.split(/\n/).map(paragrafo => (
                  <Typography
                    component="p"
                    variant="body2"
                    key={Math.random(9999)}
                    className={classes.paragrafo}
                    dangerouslySetInnerHTML={{ __html: paragrafo }}
                  />
                ))}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.buttons}>
          <Button
            type="button"
            variant="text"
            className={classes.button}
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => dispatch(updateStep(currentStep - 1))}
            variant="contained"
            className={classes.button}
            color="secondary"
          >
            Anterior
          </Button>
          <ModalAssinatura gerarContrato={gerarContrato} />
        </Grid>
      </Grid>
      <ContratoSucessoModal open={openModalSuccess} />
    </>
  );
}

Conferencia.propTypes = {
  handleCancel: PropTypes.func.isRequired,
};

export default Conferencia;
