import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '~/services/api';
import { toast } from 'react-toastify';
import history from '~/services/history';
import {
  storeContractSuccess,
  loadPaymentPlansSuccess,
  loadPaymentPlanDetailsSuccess,
} from './actions';
import { hideLoading } from '../loading/actions';
import { requestVisitas } from '../visitas/actions';

export function* loadPaymentPlans({ payload }) {
  try {
    const response = yield call(api.get, '/payment-plans', {
      params: {
        cursos: payload.cursos,
      },
    });
    yield put(loadPaymentPlansSuccess(response.data));
  } catch (err) {
    toast.info('Nenhum plano de pagamento configurado.');
  } finally {
    yield put(hideLoading());
  }
}

export function* loadPaymentPlanDetails({ payload }) {
  try {
    const response = yield call(api.get, `/payment-plan/${payload.id}`);
    yield put(loadPaymentPlanDetailsSuccess(response.data));
  } catch (err) {
    toast.error('Erro ao carregar detalhes do plano de pagamento.');
  } finally {
    yield put(hideLoading());
  }
}

export function* storeContract({ payload }) {
  try {
    yield call(api.post, '/contrato', payload);
    yield put(storeContractSuccess());
    toast.success('Contrato salvo com sucesso.');
    yield put(requestVisitas());
    history.push('/visitas');
  } catch (err) {
    toast.error('Erro ao salvar contrato.');
  } finally {
    yield put(hideLoading());
  }
}

export default all([
  takeLatest('@newContract/LOAD_PAYMENT_PLANS', loadPaymentPlans),
  takeLatest('@newContract/LOAD_PAYMENT_PLAN_DETAILS', loadPaymentPlanDetails),
  takeLatest('@newContract/STORE_CONTRACT', storeContract),
]);
