import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  AppBar,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
  Box,
  makeStyles,
  Checkbox,
  ListItemText,
  // TextField,
} from '@material-ui/core';
import * as Yup from 'yup';

import {
  storeContractData,
  storeLivros,
  storeMaterias,
  updateFiltrosTurma,
  updateLivrosSelecionados,
  updateMateriasSelecionadas,
  updateStep,
} from '~/store/modules/new-contract/actions';
import api from '~/services/api';
import TableMaterias from './TableMaterias';
import TableLivros from './TableLivros';

const useStyles = makeStyles({
  mainContainer: {
    padding: '20px',
  },
  gridTitular: {
    background: '#E4E6EE',
    borderRadius: '20px',
    height: '100%',
  },
  gridTitularContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  titleSection: {
    margin: '20px 0',
  },
  button: {
    minWidth: '171px',
    borderRadius: '200px',
    marginLeft: '15px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  box: {
    borderRadius: '8px',
  },
});

function Contrato({ handleCancel }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentStep = useSelector(state => state.newContract.currentStep);
  const dadosContrato = useSelector(state => state.newContract.dadosContrato);
  const tiposContrato = useSelector(state => state.lookup.tipoContrato);
  const modalidadesContrato = useSelector(
    state => state.lookup.modalidadeContrato
  );
  const activedCourses = useSelector(state => state.cursos.actived);
  const filtrosAplicados = useSelector(state => state.newContract.filtrosTurma);

  const [currentTable, setCurrentTable] = React.useState(0);

  const formik = useFormik({
    initialValues: dadosContrato,
    validationSchema: Yup.object({
      cursos: Yup.array()
        .of(Yup.number())
        .required('Escolha ao menos um curso.'),
      tipo_contrato: Yup.string().required('O tipo do contrato é obrigatório.'),
      modalidade_contrato: Yup.string().required(
        'A modalidade do contrato é obrigatória.'
      ),
      evento: Yup.string(),
      vendedor: Yup.string(),
      divulgador: Yup.string(),
    }),
    onSubmit: values => {
      dispatch(
        updateFiltrosTurma({ ...filtrosAplicados, cursos: values.cursos })
      );
      dispatch(
        storeContractData({
          ...values,
          livrosSelecionados: dadosContrato.livrosSelecionados,
          materiasSelecionadas: dadosContrato.materiasSelecionadas,
        })
      );
      dispatch(updateStep(currentStep + 1));
    },
  });

  const handleChangeTableTab = (event, newValue) => {
    setCurrentTable(newValue);
  };

  const getTable = () => {
    switch (currentTable) {
      case 0:
        return <TableMaterias />;
      case 1:
        return <TableLivros />;
      // case 2:
      //   return (
      //     <TableMaterias
      //       materias={materias}
      //       showMateriasGratuitas
      //       selectedMaterias={selectedMateriasGratuitas}
      //       setSelectedMaterias={setSelectedMateriasGratuitas}
      //     />
      //   );
      default:
        return 'Unknown step';
    }
  };

  const getMaterias = async selectedCourses => {
    const response = await api.get('/materias', {
      params: { cursos: selectedCourses },
    });
    dispatch(storeMaterias(response.data));
    dispatch(updateMateriasSelecionadas(response.data));
  };

  const getLivros = async selectedCourses => {
    const response = await api.get('/livros', {
      params: { cursos: selectedCourses },
    });
    dispatch(storeLivros(response.data));
    dispatch(updateLivrosSelecionados(response.data));
  };

  const renderSelectedCourses = () => {
    const descricoes = [];

    activedCourses
      .filter(curso => formik.values.cursos.includes(curso.curso_id))
      .map(curso => descricoes.push(curso.descricao));

    return descricoes.join(', ');
  };

  const handleChangeCursos = async event => {
    formik.setFieldValue('cursos', event.target.value);
    if (event.target.value.length > 0) {
      await getMaterias(event.target.value);
      await getLivros(event.target.value);
    } else {
      dispatch(storeMaterias([]));
      dispatch(storeLivros([]));
      dispatch(updateMateriasSelecionadas([]));
      dispatch(updateLivrosSelecionados([]));
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* <button
        onClick={() => dispatch(updateStep(currentStep + 1))}
        type="button"
      >
        Passar
      </button> */}
      <Grid container spacing={2} className={classes.mainContainer}>
        <Grid item xs={12} className={classes.titleSection}>
          <Typography variant="h6" color="primary">
            Dados do contrato
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="filled" required>
            <InputLabel id="cursos-label">Cursos</InputLabel>
            <Select
              labelId="cursos-label"
              multiple
              name="cursos"
              value={formik.values.cursos}
              onChange={handleChangeCursos}
              renderValue={renderSelectedCourses}
              variant="filled"
            >
              {activedCourses.map(curso => (
                <MenuItem key={curso.curso_id} value={curso.curso_id}>
                  <Checkbox
                    checked={formik.values.cursos.indexOf(curso.curso_id) > -1}
                  />
                  <ListItemText primary={curso.descricao} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <FormControl
            variant="filled"
            fullWidth
            required
            error={
              formik.touched.tipo_contrato &&
              Boolean(formik.errors.tipo_contrato)
            }
          >
            <InputLabel id="select-tipo-filled-label">
              Tipo do contrato
            </InputLabel>
            <Select
              labelId="select-tipo-filled-label"
              name="tipo_contrato"
              value={formik.values.tipo_contrato}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="">
                <em>Escolha uma opção</em>
              </MenuItem>
              {tiposContrato.map(item => (
                <MenuItem key={item.intkey} value={item.intkey}>
                  {item.descricao}
                </MenuItem>
              ))}
            </Select>

            {formik.touched.tipo_contrato && formik.errors.tipo_contrato && (
              <FormHelperText error>
                {formik.errors.tipo_contrato}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <FormControl
            variant="filled"
            fullWidth
            required
            error={
              formik.touched.modalidade_contrato &&
              Boolean(formik.errors.modalidade_contrato)
            }
          >
            <InputLabel id="select-tipo-filled-label">
              Modalidade do contrato
            </InputLabel>
            <Select
              labelId="select-tipo-filled-label"
              name="modalidade_contrato"
              value={formik.values.modalidade_contrato}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="">
                <em>Escolha uma opção</em>
              </MenuItem>
              {modalidadesContrato.map(item => (
                <MenuItem key={item.intkey} value={item.intkey}>
                  {item.descricao}
                </MenuItem>
              ))}
            </Select>

            {formik.touched.modalidade_contrato &&
              formik.errors.modalidade_contrato && (
                <FormHelperText error>
                  {formik.errors.modalidade_contrato}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <FormControl
            variant="filled"
            fullWidth
            error={formik.touched.evento && Boolean(formik.errors.evento)}
          >
            <InputLabel id="select-evento-filled-label">Evento</InputLabel>
            <Select
              labelId="select-evento-filled-label"
              name="evento"
              value={formik.values.evento}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="">
                <em>Escolha uma opção</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>

            {formik.touched.evento && formik.errors.evento && (
              <FormHelperText error>{formik.errors.evento}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <FormControl
            variant="filled"
            fullWidth
            error={formik.touched.vendedor && Boolean(formik.errors.vendedor)}
          >
            <InputLabel id="select-vendedor-filled-label">Vendedor</InputLabel>
            <Select
              labelId="select-vendedor-filled-label"
              name="vendedor"
              value={formik.values.vendedor}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="">
                <em>Escolha uma opção</em>
              </MenuItem>
              {['João da Silva', 'Maria da Silva'].map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>

            {formik.touched.vendedor && formik.errors.vendedor && (
              <FormHelperText error>{formik.errors.vendedor}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <FormControl
            variant="filled"
            fullWidth
            error={
              formik.touched.divulgador && Boolean(formik.errors.divulgador)
            }
          >
            <InputLabel id="select-divulgador-filled-label">
              Divulgador
            </InputLabel>
            <Select
              labelId="select-divulgador-filled-label"
              name="divulgador"
              value={formik.values.divulgador}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="">
                <em>Escolha uma opção</em>
              </MenuItem>
              {['José da Silva', 'Pedro da Silva'].map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>

            {formik.touched.divulgador && formik.errors.divulgador && (
              <FormHelperText error>{formik.errors.divulgador}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.box}>
            <AppBar position="static" color="primary">
              <Tabs
                variant="fullWidth"
                value={currentTable}
                onChange={handleChangeTableTab}
                indicatorColor="secondary"
              >
                <Tab label="Matéria" />
                <Tab label="Livros" />
                {/* <Tab label="Matérias Gratuitas" /> */}
              </Tabs>
            </AppBar>
            <div>{getTable()}</div>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.buttons}>
          <Button
            type="button"
            variant="text"
            className={classes.button}
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => dispatch(updateStep(currentStep - 1))}
            variant="contained"
            className={classes.button}
            color="secondary"
          >
            Anterior
          </Button>
          <Button
            type="submit"
            variant="contained"
            className={classes.button}
            color="primary"
            disabled={!formik.values.cursos.length}
          >
            Avançar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

Contrato.propTypes = {
  handleCancel: PropTypes.func.isRequired,
};

export default Contrato;
