import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// import { DataGrid } from 'devextreme-react';
// import { Column } from 'devextreme-react/data-grid';

import {
  addInstallment,
  loadPaymentPlanDetails,
  loadPaymentPlans,
  storeFinancialData,
  updateStep,
} from '~/store/modules/new-contract/actions';
import { showLoading } from '~/store/modules/loading/actions';
import PlanoPagamentoForm from './PlanoPagamentoForm';
import PlanoPagamentoGrid from './PlanoPagamentoGrid';

const useStyles = makeStyles({
  mainContainer: {
    padding: '20px',
  },
  gridTitular: {
    background: '#E4E6EE',
    borderRadius: '20px',
    height: '100%',
  },
  gridTitularContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  titleSection: {
    margin: '20px 0',
  },
  button: {
    minWidth: '171px',
    borderRadius: '200px',
    marginLeft: '15px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  box: {
    borderRadius: '8px',
  },
  gridForm: {
    position: 'relative',
    padding: '20px',
  },
});

function Financeiro({ handleCancel }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentStep = useSelector(state => state.newContract.currentStep);
  const dadosContrato = useSelector(state => state.newContract.dadosContrato);
  const dadosFinanceiro = useSelector(
    state => state.newContract.dadosFinanceiro
  );
  const paymentPlans = useSelector(state => state.newContract.paymentPlans);
  const [refresh, setRefresh] = React.useState(false);

  const getSchemaValidationByType = type => {
    return Yup.object().shape({
      valor: Yup.string().required('Valor é obrigatório'),
      data: Yup.string()
        .matches(
          /(19|20)\d{2}-(0[1-9]|1[0,1,2])-(0[1-9]|[12][0-9]|3[01])/,
          'Data inválida'
        )
        .required('Data é obrigatória'),
      alteravencimento: Yup.boolean().nullable(),
      emreais: Yup.boolean().required('Em reais é obrigatório'),
      desconto: Yup.number()
        .transform((value, originalValue) => {
          return Number.isNaN(originalValue)
            ? undefined
            : parseFloat(originalValue);
        })
        .max(
          (dadosFinanceiro[type] &&
            Number(dadosFinanceiro[type][0].descontomaximo)) ||
            0,
          'Valor máximo de desconto excedido'
        )
        .nullable(),
      descontominimo: Yup.number().nullable(),
      descontomaximo: Yup.number().nullable(),
      quantidade: Yup.number()
        .integer()
        .required('Quantidade é obrigatória'),
      bancocobranca: Yup.string().nullable(),
      cc_bancocobranca: Yup.string().nullable(),
      cc_parcelamento_max: Yup.number()
        .integer()
        .nullable(),
      demaisvencimentos: Yup.number()
        .min(1)
        .max(31)
        .nullable(),
      diautil: Yup.bool(),
    });
  };

  const formik = useFormik({
    initialValues: dadosFinanceiro,
    validationSchema: Yup.object().shape({
      planopagamento_id: Yup.number().nullable(),
      matricula: Yup.array().of(getSchemaValidationByType('matricula')),
      parcela: Yup.array().of(getSchemaValidationByType('parcela')),
      material: Yup.array().of(getSchemaValidationByType('material')),
    }),
    onSubmit: values => {
      dispatch(storeFinancialData(values));
      dispatch(updateStep(currentStep + 1));
    },
  });

  const handleChangePlan = event => {
    formik.handleChange(event);
    dispatch(showLoading('Carregando detalhes do plano de pagamento...'));
    dispatch(loadPaymentPlanDetails(event.target.value));
    setRefresh(!refresh);
  };

  React.useEffect(() => {
    if (!paymentPlans) {
      dispatch(showLoading('Carregando planos de pagamento...'));
      dispatch(loadPaymentPlans(dadosContrato.cursos));
    }
  }, [paymentPlans]); // eslint-disable-line

  React.useEffect(() => {
    formik.setValues({ ...dadosFinanceiro });
  }, [dadosFinanceiro]); // eslint-disable-line

  const handleAddRow = type => {
    const newRow = {
      valor: '0',
      data: '',
      diavencimento: null,
      alteravencimento: true,
      emreais: true,
      desconto: '0',
      descontominimo: 0,
      descontomaximo: 0,
      quantidade: 0,
      bancocobranca: null,
      cc_bancocobranca: null,
      cc_parcelamento_max: null,
      type,
    };

    const installments = [...formik.values[type]];

    installments.push(newRow);

    dispatch(addInstallment({ ...dadosFinanceiro, [type]: installments }));
    formik.setValues({ ...dadosFinanceiro, [type]: installments });
  };

  const handleRemoveRow = (type, index) => {
    const installments = [...formik.values[type]];
    installments.splice(index, 1);
    dispatch(addInstallment({ ...dadosFinanceiro, [type]: installments }));
    formik.setValues({ ...dadosFinanceiro, [type]: installments });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} className={classes.mainContainer}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.titleSection}>
              <Typography variant="body1" color="primary">
                Dados sobre o contrato
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="filled"
                fullWidth
                error={
                  formik.touched.planopagamento_id &&
                  Boolean(formik.errors.planopagamento_id)
                }
              >
                <InputLabel id="select-banco-filled-label">
                  Plano de pagamento
                </InputLabel>
                <Select
                  labelId="select-banco-filled-label"
                  name="planopagamento_id"
                  value={formik.values.planopagamento_id}
                  onChange={handleChangePlan}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value="">
                    <em>Escolha uma opção</em>
                  </MenuItem>
                  {paymentPlans &&
                    paymentPlans.length > 0 &&
                    paymentPlans.map(item => (
                      <MenuItem
                        key={item.planopagamento_id}
                        value={item.planopagamento_id}
                      >
                        {item.descricao}
                      </MenuItem>
                    ))}
                </Select>

                {formik.touched.planopagamento_id &&
                  formik.errors.planopagamento_id && (
                    <FormHelperText error>
                      {formik.errors.planopagamento_id}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
            {dadosFinanceiro && formik.values.planopagamento_id && (
              <Grid container spacing={1} className={classes.gridForm}>
                {dadosFinanceiro.matricula.map((_, index) => (
                  <PlanoPagamentoForm
                    indice={index}
                    formik={formik}
                    section="matricula"
                    sectionTitle="Matrícula"
                    key={`matricula_${index}`} // eslint-disable-line
                    handleAddRow={handleAddRow}
                    handleRemoveRow={handleRemoveRow}
                  />
                ))}
                <Grid item xs={12}>
                  <Divider variant="fullWidth" />
                </Grid>
                {dadosFinanceiro.parcela.map((_, index) => (
                  <PlanoPagamentoForm
                    indice={index}
                    formik={formik}
                    section="parcela"
                    sectionTitle="Parcela"
                    key={`parcela_${index}`} // eslint-disable-line
                    handleAddRow={handleAddRow}
                    handleRemoveRow={handleRemoveRow}
                  />
                ))}
                <Grid item xs={12}>
                  <Divider variant="fullWidth" />
                </Grid>
                {dadosFinanceiro.material.map((_, index) => (
                  <PlanoPagamentoForm
                    indice={index}
                    formik={formik}
                    section="material"
                    sectionTitle="Material"
                    key={`material_${index}`} // eslint-disable-line
                    handleAddRow={handleAddRow}
                    handleRemoveRow={handleRemoveRow}
                  />
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid item xs={12} className={classes.titleSection}>
              <Typography variant="body1" color="primary">
                Situação Financeira
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <PlanoPagamentoGrid planoPagamento={formik.values} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.buttons}>
          <Button
            type="button"
            variant="text"
            className={classes.button}
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => dispatch(updateStep(currentStep - 1))}
            variant="contained"
            className={classes.button}
            color="secondary"
          >
            Anterior
          </Button>
          <Button
            type="submit"
            variant="contained"
            className={classes.button}
            color="primary"
          >
            Avançar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

Financeiro.propTypes = {
  handleCancel: PropTypes.func.isRequired,
};

export default Financeiro;
